<template>
  <div>
    <vue-identify-network
      v-show="showNetworkSpeed"
      id="network-speed"
      class="font-weight-bold white--text"
      @network-type="handleNetworkIdentified"
      @network-speed="handleNetworkSpeed"
    >
      <template #unknown>
        <v-badge
          :color="
            speed >= 4
              ? 'lime accent-3'
              : speed >= 2
              ? 'orange darken-3'
              : 'red accent-4'
          "
          class="mr-5"
        />
        {{ networkQuality }} | {{ speed }}Mbps
      </template>
      <template #slow>
        <v-badge
          :color="
            speed >= 4
              ? 'lime accent-3'
              : speed >= 2
              ? 'orange darken-3'
              : 'red accent-4'
          "
          class="mr-5"
        />
        {{ networkQuality }} | {{ speed }}Mbps
      </template>
      <template #fast>
        <v-badge
          :color="
            speed >= 4
              ? 'lime accent-3'
              : speed >= 2
              ? 'orange darken-3'
              : 'red accent-4'
          "
          class="mr-5"
        />
        {{ networkQuality }} | {{ speed }}Mbps
      </template>
    </vue-identify-network>
  </div>
</template>

<script>
import "@zoomus/websdk/dist/css/bootstrap.css";
import "@zoomus/websdk/dist/css/react-select.css";
import { ZoomMtg } from "@zoomus/websdk";
import { getSecrets } from "@/api/admin/academic/schedule";
import { VueIdentifyNetwork } from "vue-identify-network";

export default {
  created() {
    this.init();
  },
  components: {
    VueIdentifyNetwork
  },
  computed: {
    networkQuality() {
      let result;
      if (this.speed >= 5) result = "Sinyal Bagus";
      else if (this.speed >= 3) result = "Sinyal Kurang Bagus";
      else result = "Sinyal Buruk";
      return result;
    }
  },
  data() {
    return {
      isHost: false,
      userName: "",
      speed: 0,
      showNetworkSpeed: false
    };
  },
  methods: {
    // handleNetworkIdentified(type) {
    //   console.log("connection type: ", type);
    // },
    handleNetworkSpeed(speed) {
      this.speed = speed;
    },
    // eventListener(event) {
    //   event.preventDefault();
    //   event.returnValue = "";
    // },
    // msgCloseTab() {
    //   let msg;
    //   if (this.isHost) {
    //     msg = this.$i18n.t("schedule.close_tab_host");
    //   } else {
    //     msg = this.$i18n.t("schedule.close_tab_participant");
    //   }
    //   return msg;
    // },
    // snackBar(isError, msg, timeout) {
    //   this.$store.commit("CALL_SNACKBAR", {
    //     msg: msg,
    //     color: isError ? "error" : "success",
    //     timeout: timeout
    //   });
    // },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async init() {
      ZoomMtg.setZoomJSLib("https://source.zoom.us/2.0.1/lib", "/av");
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      // document.getElementById("zmmtg-root").style.display = "block";
      const secrets = await getSecrets();
      // decrypt JWT
      const dataSecrets = this.parseJwt(secrets.data.data);
      /**
       * 0 = participant
       * 1 = host
       */
      const zoomOptions = this.parseJwt(this.$route.query.options);
      // Generate Signature function
      const signature = ZoomMtg.generateSignature({
        meetingNumber: zoomOptions.zoom_id,
        apiKey: dataSecrets.zoom_api_key,
        apiSecret: dataSecrets.zoom_api_secret,
        role: zoomOptions.is_host,
        success: res => {
          console.log("success signature: " + res.result);
        },
        error: err => {
          console.error("generateSignature()\n", err);
        }
      });
      // join function
      ZoomMtg.init({
        leaveUrl: window.origin,
        isSupportAV: true,
        success: () => {
          ZoomMtg.join({
            meetingNumber: zoomOptions.zoom_id,
            userName: zoomOptions.name,
            signature: signature,
            apiKey: dataSecrets.zoom_api_key,
            passWord: zoomOptions.zoom_password,
            success: res => {
              // ZoomMtg.getCurrentUser({
              //   success: msg => {
              //     const data = msg.result.currentUser;
              //     this.isHost = data.isHost;
              //     this.userName = data.userName;
              //     if (zoomOptions.name === this.userName) {
              //       window.addEventListener("beforeunload", event => {
              //         this.eventListener(event);
              //         this.snackBar(true, this.msgCloseTab(), -1);
              //       });
              //     }
              //   },
              //   error: msg => {
              //     console.error(msg);
              //   }
              // });
              // ZoomMtg.inMeetingServiceListener("onUserLeave", msg => {
              //   if (zoomOptions.name === msg.userName) {
              //     window.removeEventListener(
              //       "beforeunload",
              //       this.eventListener
              //     );
              //   }
              //   if (!msg.isHold) {
              //     endMeeting({
              //       schedule_id: zoomOptions.schedule_id,
              //       person: this.$store.getters.g_user.person
              //     });
              //   }
              //   console.error("onUserLeave", msg);
              // });
              this.showNetworkSpeed = true;
              console.log("join meeting success", res);
            },
            error: err => {
              console.error("join()\n", err);
            }
          });
        },
        error: res => {
          console.error(res);
        }
      });
      // ZoomMtg.inMeetingServiceListener("onUserLeave", msg => {
      //   endMeeting({
      //     schedule_id: zoomOptions.schedule_id,
      //     person: this.$store.getters.g_user.person
      //   });
      //   console.log("onUserLeave", msg);
      // });
    }
  }
};
</script>

<style lang="scss">
// #main-video {
//   transform: rotateY(180deg);
//   -webkit-transform: rotateY(180deg); /* Safari and Chrome */
//   -moz-transform: rotateY(180deg); /* Firefox */
// }

// #main-video,
// #suspension-video {
//   transform: scaleX(-1);
// }
#network-speed {
  position: fixed;
  left: 10px;
  bottom: 60px;
  z-index: 100;
  opacity: 0.5;
}
</style>
