var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-identify-network',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNetworkSpeed),expression:"showNetworkSpeed"}],staticClass:"font-weight-bold white--text",attrs:{"id":"network-speed"},on:{"network-type":_vm.handleNetworkIdentified,"network-speed":_vm.handleNetworkSpeed},scopedSlots:_vm._u([{key:"unknown",fn:function(){return [_c('v-badge',{staticClass:"mr-5",attrs:{"color":_vm.speed >= 4
            ? 'lime accent-3'
            : _vm.speed >= 2
            ? 'orange darken-3'
            : 'red accent-4'}}),_vm._v(" "+_vm._s(_vm.networkQuality)+" | "+_vm._s(_vm.speed)+"Mbps ")]},proxy:true},{key:"slow",fn:function(){return [_c('v-badge',{staticClass:"mr-5",attrs:{"color":_vm.speed >= 4
            ? 'lime accent-3'
            : _vm.speed >= 2
            ? 'orange darken-3'
            : 'red accent-4'}}),_vm._v(" "+_vm._s(_vm.networkQuality)+" | "+_vm._s(_vm.speed)+"Mbps ")]},proxy:true},{key:"fast",fn:function(){return [_c('v-badge',{staticClass:"mr-5",attrs:{"color":_vm.speed >= 4
            ? 'lime accent-3'
            : _vm.speed >= 2
            ? 'orange darken-3'
            : 'red accent-4'}}),_vm._v(" "+_vm._s(_vm.networkQuality)+" | "+_vm._s(_vm.speed)+"Mbps ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }